import React, { useEffect, useState } from 'react'
import ModalProduct from './partials/ModalProduct'
import Accordion from './partials/Accordion'
import Footer from '../Footer/Footer'
import './modal.css'
import RecommendedProducts from './partials/RecommendedProducts'

const Modal = (
  { 
    clientCart,
    body_butter, 
    serum, 
    addToCart,
    productAdded
  }
  ) => {

  const [serumReviews, setSerumReview] = useState('')
  const [bodyButterReviews, setBodyButterReviews] = useState('')

  useEffect(()=>{
    window.scrollTo(0,0)

    if(serum){
      const serumReviewsArray = serum[0].reviews;
      setSerumReview(serumReviewsArray);
    }else if(body_butter){
      const bodyButterReviews = body_butter.map(getReviews => getReviews.reviews)
      setBodyButterReviews(bodyButterReviews)
    };
  },[])

  return (
    <div className='modal'>
      <ModalProduct 
        clientCart={clientCart}
        body_butter={body_butter}
        serum={serum} 
        addToCart={addToCart}
        productAdded={productAdded}
      />
      <Accordion 
        serum={serum}
        serumReviews={serumReviews} 
        bodyButterReviews={bodyButterReviews}
      />
      <RecommendedProducts />
      <Footer />
    </div>
  )
}

export default Modal