import React from 'react'
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import Footer from '../Footer/Footer'
import AboutLanding from './partials/AboutLanding'
import AboutLandingMobile from './partials/AboutLandingMobile'
import "./About.css"

const About = ({broll_images}) => {
  const isMobile = useMediaQuery({query: '(max-width:767px)'})

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <div className='about'>
       {
        isMobile ? <AboutLandingMobile /> : <AboutLanding broll_images={broll_images} />
       }  
       <Footer />
    </div>
  )
}

export default About