// dependencies
import React, {useState} from 'react'
import './navigation.css'
import { Link } from 'react-router-dom'
import { Image } from 'cloudinary-react'
import { useMediaQuery } from 'react-responsive'

// icons 
import {GiHamburgerMenu} from 'react-icons/gi'
import {AiOutlineClose} from 'react-icons/ai'
import {BsFillBagFill} from 'react-icons/bs'

const Navigation = ({broll_images, observerState}) => {
    const isMobile = useMediaQuery({ query: `(max-width: 767px)` });
    const cloudName = 'dyzydm9nl'

    // useState
    const [navLinksActive, setNavLinksActive] = useState(false);
    const [shopActive, setShopActive] = useState(false);

    const closeNavigation = () =>{
        setNavLinksActive(false);
        setShopActive(false);
    }

  return (
    <div className={observerState ? 'navigation' : 'navigation addObserverColor'}>
        <div className="info">
            <strong>For 20% off your next order please send us a barefaced selfie to be featured on our blog! </strong>
        </div>
        <div className="nav_bar">
            <GiHamburgerMenu className='open_navigation' size={30} onClick={()=>setNavLinksActive(true)}/>
            <div className={navLinksActive ? "links" : 'nav_close'}>
                <ul className="nav_links">
                    <div className="close_navigation">
                        <AiOutlineClose size={30} onClick={()=>closeNavigation()}/>
                    </div>
                    <Link onClick={()=>closeNavigation()} to='/about'><li>About</li></Link>
                    <Link onClick={()=>closeNavigation()} to='/'><li>Home</li></Link>
                    <Link onMouseEnter={()=>setShopActive(true)}><li>Shop</li></Link>
                    <Link onClick={()=>closeNavigation()} to='/skin-quiz'><li>Skin Quiz</li></Link>
                    <Link onClick={()=>closeNavigation()} to='/blog'><li>Blog</li></Link>
                    <ul className={ shopActive ? 'shop_active' : 'shop_not_active'}>
                        <div className="container">
                            <Link onClick={()=>closeNavigation()} to='/shop/serum'><li>Serum</li></Link>
                            <Link onClick={()=>closeNavigation()} to='/shop/body-butter'><li>Body Butter</li></Link>
                        </div>
                    </ul>
                    {
                        isMobile && 
                        <Link to='/cart'>
                        <span onClick={()=>closeNavigation()} className='cart-mobile'>
                            <BsFillBagFill 
                                className='cart' 
                                size={25}
                            />
                        </span>
                    </Link>
                    }
                </ul>
            </div>
            <div className="logo">
                {
                    broll_images.filter(broll_image => broll_image.name === 'logo').map(broll_image => 
                        <Link 
                            onClick={closeNavigation}
                            key={broll_image.id} 
                            to='/'><Image cloudName={cloudName} publicId={broll_image.image} />
                        </Link>
                    )
                }
            </div>
            {
                !isMobile && 
                <div className="right_side">
                    <Link to='/cart'>
                        <span>
                            <BsFillBagFill 
                                className='cart' 
                                size={25}
                            />
                        </span>
                    </Link>
                </div>
            }
        </div>
    </div>
  )
}

export default Navigation