import { Image } from 'cloudinary-react'
import React from 'react'



const Entrance = ({serum, myref}) => {
    const cloudName = 'dyzydm9nl'
    
  return (
    <div ref={myref} className='entrance-container'>
        {
            serum.map(image => image.name === 'Granted Glow' &&
                <div className='image-container' key={image.id}>
                    <Image cloudName={cloudName} publicId={image.image10}/>
                </div>
            )
        }
    </div>
  )
}

export default Entrance