import React, {useEffect, useState} from 'react'
import { Image } from 'cloudinary-react';
import { Link, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';

const ModalProduct = (
    {
        body_butter, 
        serum, 
        addToCart,
        productAdded
    }
    ) => {
        const screenSize = {
            mobile: useMediaQuery({ query: `(max-width: 767px)`}),
            tablet: useMediaQuery({query: `(min-width: 768px) and (max-width: 1023px)`}),
            desktop: useMediaQuery({query: `(min-width: 1024px)`})
        }
        const {productId} = useParams()
        const cloudName = 'dyzydm9nl';
        const [mainStage, setMainStage] = useState("https://res.cloudinary.com/dyzydm9nl/image/upload/v1666448871/landingImage2_rflq6t.png")

        const renderStripePaymentFromModal = async () => {
            await axios('https://bearysoftorganic.com/.netlify/functions/server', {
                method: 'post',
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify({items: [{id: "price_1MhLjqCUUp6fiPdVabZZzONY", qty: "1"}]})
            })
            .then(resp => {
                return resp.data
            })
            .then(resp =>{
                if(resp.message){
                    return window.location.assign(resp.message);
                }
            })
        }

        useEffect(()=>{
            window.scrollTo(0,0)
        
        },[])
    
  return (
    <div className='modal_container'>
        {
            productId === "price_1MhLjqCUUp6fiPdVabZZzONY" &&
                <>
                    {
                        serum.filter(serum => serum.id === productId).map(serum =>
                            <div key={serum.id} className='stage'>
                                <div className="stage_options">
                                    <Image
                                        onClick={()=>setMainStage(serum.image1)} 
                                        className='img' 
                                        cloudName={cloudName} 
                                        publicId={serum.image1} 
                                    />
                                    <Image
                                        onClick={()=>setMainStage(serum.image2)} 
                                        className='img' 
                                        cloudName={cloudName} 
                                        publicId={serum.image2} 
                                    />
                                    <Image
                                        onClick={()=>setMainStage(serum.image7)} 
                                        className='img' 
                                        cloudName={cloudName} 
                                        publicId={serum.image7} 
                                    />
                                    <Image
                                        onClick={()=>setMainStage(serum.image6)} 
                                        className='img' 
                                        cloudName={cloudName} 
                                        publicId={serum.image6} 
                                    />
                                    <Image
                                        onClick={()=>setMainStage(serum.image5)} 
                                        className='img' 
                                        cloudName={cloudName} 
                                        publicId={serum.image5} 
                                    />
                                </div>
                                <div className="main_stage">
                                    {
                                        screenSize.mobile && <h1 className='name'>{serum.name} - <span className='price-span'>${serum.price}</span></h1>
                                    }
                                    {
                                        screenSize.tablet && <h1 className='name'>{serum.name} - <span className='price-span'>${serum.price}</span></h1>
                                    }
                                    <Image 
                                        className='img' 
                                        cloudName={cloudName} 
                                        publicId={mainStage} 
                                    />
                                </div>
                                <div className="info">
                                    <div className="top">
                                        {
                                            screenSize.desktop && <h1 className='name'>{serum.name} - <span className='price-span'>${serum.price}</span></h1>
                                        }
                                        <button
                                            onClick={()=>addToCart(serum)}>{!productAdded ? 'Add to cart' : 'Added to cart...'}  
                                        </button>
                                        <button
                                            onClick={renderStripePaymentFromModal}>Buy Now  
                                        </button>
                                        <Link 
                                            to='/shop/serum'>
                                            <button className='return_to_shop'>Continue Shopping</button>
                                        </Link>
                                    </div>
                                    <div className="description">
                                        <p>{serum.description}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </>
            }
        {
            productId != "price_1MhLjqCUUp6fiPdVabZZzONY" &&
                <>
                    {
                        body_butter.filter(bodyButter => bodyButter.id === productId).map(bodyButter =>
                            <div key={bodyButter.id} className='stage'>
                                {
                                    screenSize.mobile && 
                                    <h1 className='name'>{bodyButter.name} - <span className='price-span'>${bodyButter.price}</span></h1>
                                }
                                <div className="stage_options">
                                    <Image 
                                        className='img' 
                                        cloudName={cloudName} 
                                        publicId={bodyButter.image} 
                                    />
                                    <Image 
                                        className='img' 
                                        cloudName={cloudName} 
                                        publicId={bodyButter.image} 
                                    />
                                </div>
                                <div className="main_stage">
                                    <Image 
                                        className='img' 
                                        cloudName={cloudName} 
                                        publicId={bodyButter.image} 
                                    />
                                </div>
                                <div className="info">
                                    <div className="top">
                                        {
                                            screenSize.desktop && 
                                            <h1 className='name'>{bodyButter.name} - <span className='price-span'>${bodyButter.price}</span></h1>
                                        }
                                        <button disabled onClick={()=>addToCart(bodyButter)}> Currently Unavailable
                                        </button>
                                        <Link 
                                            to='/shop/body-butter'>
                                            <button className='return_to_shop'>Continue Shopping</button>
                                        </Link>
                                    </div>
                                    <div className="description">
                                        <p>{bodyButter.description}</p>
                                    </div>
                                </div>
                        </div>
                    )
                }
            </>
        } 
    </div>
  )
}

export default ModalProduct