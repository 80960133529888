import React, { useEffect } from 'react'

const FunFact = ({nextStep}) => {

  useEffect(()=>{
    window.scroll(0,0)

  },[])

  return (
    <div className='fun_fact'>
        <div className='fun_fact_container'>
            <p> <span>Fun Fact:</span> Individuals with dry skin skin cell takes longer to rejuvenate over time cause the skin to lack hydration. Oily skin has a faster skin cell turn over that allows the skin to more produce oil. Learning your texture helps us create a regime that is adjusted so our skin can benefit. Either is your skin is normal or a combination of both it helps find product the suits your skin story! </p> 
        </div>
        <div className="fun_fact_redirects">
          <button onClick={()=>nextStep('question two')}>Continue</button>
        </div>
    </div>
  )
}

export default FunFact