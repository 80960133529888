import React, {useEffect, useState} from 'react'

const QuestionThree = ({nextStep}) => {

  const [formData, setFormData] = useState({
    firstValue : '',
    secondValue : ''
  })

  const {firstValue, secondValue} = formData;

  const handleChange = (e) =>{
    setFormData(formData => {
      return {
        ...formData, [e.target.name] : e.target.value
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    nextStep('question four', formData)
  }

  useEffect (()=>{
    window.scrollTo(0,0)
  
},[])

  return (
    <div className='question_three'>
      <div className="q_a">
        <form onSubmit={handleSubmit}>
          <div className="questions">
            <h1>3A. What products are on/in your shelf?</h1>
            <input required type="text" name="firstValue" id="" onChange={(e)=>handleChange(e)} value={firstValue} />
          </div>
          <div className="questions">
            <h1>3B. What are you incorporating into your AM / PM skin schedule?</h1>
            <input required type="text" name="secondValue" id="" onChange={(e)=>handleChange(e)} value={secondValue} />
          </div>
          <button>Next</button>
        </form>
      </div>
    </div>
  )
}

export default QuestionThree