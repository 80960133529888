import React from 'react'
import { Image } from 'cloudinary-react'
import { useMediaQuery } from 'react-responsive'

const SkinPlaylist = ({broll_images}) => { 
  const cloudName = 'dyzydm9nl'
  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  return (
    <div className='skin_playlist_container'>
       <div className="content">
          <h1>Tame the Wild Ones </h1>
          <p> 
            Singing skin is what I refer to as the rhythmic function and dysfunction of our skin. 
            <br />
            When our body is irritated or in dis-ease , the body shows signs and signals. 
            <br /> 
            It’s imperative to keep our organ healthy & nourished.
            <br />
            Skin eruptions like eczema rashes are natural flares that occur and can be passed down genetically causing an individual to have sensitive skin. 
          </p>
          {
              isMobile &&
              broll_images.filter(broll_image => broll_image.name === 'Skin Playlist').map(broll_image => 
                <div key={broll_image.id} className='image'>
                  <Image className='img' cloudName={cloudName} publicId={broll_image.image} />
                </div>  
              )
            }
          <p>
            <br />
             You can also be an individual whom skin suffers from acne because of build of bacteria. 
            <br />
            This can appear as dry skin and blackheads or oily skin and whitehead. 
            <br /> 
            The best advice when it’s comes to your skin is advising you to listen to your skin sound and getting to understand your body better, in whole. “Tame the Wild One” playlist spotlights skin struggles while also providing a skin solution. 
            <br />
            Is your skin singing and are you listening to your skin story?
          </p>
       </div>
       {
        !isMobile &&
        broll_images.filter(broll_image => broll_image.name === 'Skin Playlist').map(broll_image => 
          <div key={broll_image.id} className='image'>
            <Image className='img' cloudName={cloudName} publicId={broll_image.image} />
          </div>  
        )
       }
    </div>
  )
}

export default SkinPlaylist