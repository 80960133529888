import React from 'react'

const Accordion = () => {
  return (
    <div>
        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Is BearySoftOrganic products all natural?
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <li>Yes, our ingredients are cold pressed extracted from the plants. All handmade and cultivated with love!</li>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Can I use BearySoftOrganic if I have allergies?
                </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <li>I advise you to please read the ingredients before purchasing any products or reaching out to us via email at <a target="_blank" href="mailto:Bearysoftorganic@gmail.com">Bearysoftorganic@gmail.com</a> We are not responsible for any breakout and advise you to consult your PCP to do an allergy test.</li> 
                        <li>Also, it’s best to test the product on your inner arm or elbow to see how your skin reacts before applying to your face.</li>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    How long will it take to receive my package?
                </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <li>Please allow 5-10 days for your order to be processed before shipment. Packaged are to be delivered in 2-3 business days via USPS.</li>
                        <li>Once your order is shipped you will be able to use your tracking number to track your delivery date. Tracking numbers will be issued 24-48 hours later.</li>
                       <li>Please contact your local post office if there any issues with your delivery.</li>
                       <li>Please be sure to provide the correct address to ensure your package is safely delivered to the correct destination. Please don’t forget double check and include any suites units or apartments numbers.</li>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                   What’s your Refund Policy?
                </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <li>We do not offer any refunds on any order that has been successfully processed. All orders are final sale and we do not offer returns or exchanges. For any concerns on your order please contact <a target="_blank" href="mailto:Bearysoftorganic@gmail.com">Bearysoftorganic@gmail.com</a></li>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    What should I do if I receive a damaged product?
                </button>
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                       <li>If your product is damage please  email us <a target="_blank" href="mailto:Bearysoftorganic@gmail.com">Bearysoftorganic@gmail.com</a> and file a claim with your local USPS. In the email attach your claim and order number with a photo of the damaged product when received. We will make it our duty to ensure the circumstances are handled efficiently and effectively.</li>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    What should I do if I receive the wrong order?
                </button>
                </h2>
                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <li>Please contact/email <a target="_blank" href="mailto:Bearysoftorganic@gmail.com">Bearysoftorganic@gmail.com</a> immediately so we can work out the issue. Ensure to include your order number and a photos of the incorrect package received. We still work out the circumstances to our best convince and get you the right order.</li>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingSeven">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                    How can I reach the owner for customer support?
                </button>
                </h2>
                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <li>For any and all customer support questions or concerns,  please email us at <a target="_blank" href="mailto:Bearysoftorganic@gmail.com">Bearysoftorganic@gmail.com</a></li>   
                        <li>Please allow us 24-48 hours to respond to your email, we promise to get back to you at the earliest convenience!</li>  
                        <li>We take pride in making sure our customers are satisfied with every order. Thank you for shopping with us, we appreciate your service</li>  
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Accordion