// dependencies
import React, { useEffect } from 'react'
import './home.css'

// imports 
import LandingPage from './partials/LandingPage'
import HotProducts from './partials/HotProducts'
import Footer from '../Footer/Footer'
import SkinPlaylist from './partials/SkinPlaylist'
import Entrance from './partials/Entrance'

const Home = ({setObserverState, myref, serum, body_butter, broll_images}) => {
  useEffect(()=>{
    window.scrollTo(0,0)

    const options = {
      rootMargin: '0px',
      threshold: 0.1,
    }

    const observer = new IntersectionObserver((entries)=>{
      const entry = entries[0]
      setObserverState(entry.isIntersecting)
    }, options)
    observer.observe(myref.current)
  },[])

  return (
    <div className="home_container">
      <Entrance myref={myref} serum={serum} />
      <LandingPage serum={serum} />
      <HotProducts body_butter={body_butter} />
      <SkinPlaylist broll_images={broll_images} />
      <Footer />
    </div>
  )
}

export default Home