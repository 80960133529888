import React from 'react'
import { Link } from 'react-router-dom'

// images 
import { Image } from 'cloudinary-react'

const Products = ({body_butter, cloudName}) => {
  return (
    <div className='products_container'>
        <h1>Sweet Scents Collection</h1>
        <div className="products">
            {
                body_butter.filter(body_butter => body_butter.id !== "0").map(body_butter => 
                    <div key={body_butter.id} className='images'>
                        <Link 
                            to={`/shop/body-butter/${body_butter.id}`}
                        > 
                            <Image 
                                cloudName={cloudName} 
                                publicId={body_butter.image}
                            />
                        <h2>{body_butter.name}</h2>
                        </Link>
                    </div>    
                )
            }
        </div>
    </div>
  )
}

export default Products