import React, { useState } from 'react'

const HowToUse = ({serum}) => {
    const [openAccordian, setOpenAccordian] = useState(false);

  return (
    <div className='how_to_use'>
        <h1 onClick={()=>setOpenAccordian(!openAccordian)}>How To Use</h1>
        {
          openAccordian && 
            <div className="accordian_drop">
              {
                serum.map(serum => 
                    <video key={serum.id} width="420" height="440" autoPlay loop muted>
                      <source src={serum.howToUse} type="video/mp4"/>
                      <source src={serum.howToUse} type="video/ogg"/>
                      Your browser does not support the video tag.
                  </video>
                )
              }
            </div>
        }
    </div>
  )
}

export default HowToUse