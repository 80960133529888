import React, { useEffect } from 'react'

const SkinGoals = ({nextStep}) => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <div className='fun_fact'>
        <div className="fun_fact_container">
            <p> Securing skin solutions with results from our loyal barefaced babes skin stories. Here at BearySoftOrganic we pleasure in our customers skin satisfaction. The skincare innovation aims to fulfill skin success of those who use the product! </p>
        </div>
        <div className="fun_fact_redirects">
          <button onClick={()=>nextStep('question five')}>Continue</button>
        </div>
    </div>
  )
}

export default SkinGoals