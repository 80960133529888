import React, {useEffect} from 'react'
import { useState } from 'react'

const QuestionFive = ({nextStep}) => {
  const [userInput, setUserInput] = useState('');

  const handleChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = () => {
    nextStep('get results', userInput);
  };

    useEffect (()=>{
      window.scrollTo(0,0)
  },[]);

  return (
    <div>
        <div className='q_a'>
            <div className="questions">
                <h1> 5. What are your skin goals? </h1>
            </div>
            <form onSubmit={handleSubmit} className="answers">
              <input 
                required
                placeholder='Skin goals' 
                type="text" 
                name="" 
                id="" 
                value={userInput} 
                onChange={(e)=>handleChange(e)} 
              />
              <button className='skin_goals_button'>Finish</button>
            </form>
        </div>
    </div>
  )
}

export default QuestionFive