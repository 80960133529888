import React, {useEffect} from 'react'
import Footer from '../../Footer/Footer';
import Products from './partials/Products';
import './bodyButter.css'

const BodyButter = ({renderModal, body_butter}) => {
  const cloudName = 'dyzydm9nl';

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <div className='bodyButter'>
        <Products renderModal={renderModal} body_butter={body_butter} cloudName={cloudName} />
        <Footer />
    </div>
  )
}

export default BodyButter