import React from 'react'
import { useState, useEffect } from 'react'

const QuestionFour = ({nextStep}) => {
    const [skinConditionSteps, setSkinConditionStep] = useState (false)
    const [skinCondition, setSkinCondition] = useState('')

    const handleSubmit = (e) =>{
        e.preventDefault()
        nextStep('skin goals', skinCondition)
    }

    const handleChange = (e) =>{
        if(e.target.innerText === 'No'){
            nextStep('skin goals', 'No')
        }else{
            setSkinCondition(e.target.value)
        }
    }

    useEffect (()=>{
        window.scrollTo(0,0)
    },[])

  return (
    <div>
        <div className='q_a'>
            <div className="questions">
                <h1>4. Do you have any skin condition? </h1>
            </div>
            <div className="answers">
                <div onClick={()=>setSkinConditionStep(true)} className="container">
                    <strong>Yes</strong>
                </div>
                <div onClick={(e)=>handleChange(e)} className="container">
                    <strong>No</strong>
                </div>
                {
                    skinConditionSteps === true &&
                    <div className="skin_input">
                        <form onSubmit={(e)=>handleSubmit(e)}>
                            <input required placeholder='Enter your skin conditions' type="text" onChange={(e)=>handleChange(e)} value={skinCondition} />
                            <button className='skin_condition_button'>Next</button>
                        </form>
                    </div>
                }
            </div>
        </div>
    </div>
  )
}

export default QuestionFour