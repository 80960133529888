import { Image } from 'cloudinary-react'
import React from 'react'
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

const Success = ({broll_images, setClientCart}) => {
  const cloudName = 'dyzydm9nl'
  const isMobile = useMediaQuery({ query: `(max-width: 767px)` })

  useEffect(()=>{
    setClientCart([])
  },[])
  return (
    <div className='success'>
      <div className="left">
        <h1>Thank you for shopping with BearySoftOrganic! </h1>
        {
          isMobile &&
            broll_images.map(image => image.name === 'successful payment' && 
            <div key={image.id}>
              <Image cloudName={cloudName} publicId={image.image}/>
            </div>
          )
        }
        <p>Your payment has been successfully processed and your shipping information will be sent to your email shortly. Your support is wholeheartedly appreciated and unmatched. We hope you love your products and reach the skin goal you desire, may your glow be granted! Don’t forget to send us in your barefaced babe selfies to be featured on our website!</p>
        <Link to='/'><button>Go Back To Home</button></Link>
      </div>
      <div className="right">
        {
          !isMobile &&
            broll_images.map(image => image.name === 'successful payment' && 
            <div key={image.id}>
              <Image cloudName={cloudName} publicId={image.image}/>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default Success