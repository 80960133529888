import React, { useState } from 'react'
import { useEffect } from 'react';

const RetrieveClientInfo = ({nextStep}) => {
  
  const [formData, setFormData] = useState({
    firstName : '',
    lastName : '',
    email : ''
  })

  const {firstName, lastName, email} = formData;

  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep('question one', formData)
  }

  const handleChage = (e) => {
    setFormData({
      ...formData, [e.target.name] : e.target.value
    })
  }

  return (
    <div className='client_info'>
      <h1>Welcome to the skin quiz! <br /> Enter your name and email to continue</h1>

      <form onSubmit={handleSubmit}>
        <div className="client_name">
          <div className="container">
            <label htmlFor="firstName">First Name</label>
            <input required type="text" name="firstName" id="firstName"
            onChange={handleChage} placeholder='First Name' value={firstName} />
          </div>
          <div className="container">  
            <label htmlFor="lastName">Last Name</label>
            <input required type="text" name="lastName" id="lastName" onChange={handleChage} placeholder='Last Name' value={lastName} />
          </div>
        </div>
        <div className="email">
          <label htmlFor="email">Email</label>
          <input required type="email" name="email" onChange={handleChage} placeholder='Email' value={email}/>
        </div>
        <button>Get started</button>
      </form>
    </div>
  )
}

export default RetrieveClientInfo