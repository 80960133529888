import React from 'react'
import emailjs from "@emailjs/browser"

const ReviewForm = () => {

    const onSubmit = (e) => {
        e.preventDefault()
        e.target.reset()
        emailjs.sendForm('service_9aqmc0q', 'template_utwrvvf', e.target, 'u9VJqE7AuO9xLxWsT')
    }

  return (
    <form action="" onSubmit={onSubmit} className='review_form'>
        <div className="name review">
            <div className="name_container">
                <label 
                    htmlFor="first_name">First Name
                </label>
                <input 
                    placeholder='Enter your name' 
                    type="text" 
                    name="first_name" 
                    id="first_name" 
                />
                <label 
                    htmlFor="last_name">Last Name
                </label>
                <input 
                    placeholder='Enter your name' 
                    type="text" 
                    name="last_name" 
                    id="last_name" 
                />
            </div>
        </div>
        <div 
            className="email review"
        >
            <label 
                htmlFor="reviewer_email">Email Address
            </label>
            <input  
                placeholder='johnsmith@gmail.com' 
                type="email" 
                name="email" 
                id="reviewer_email" 
            />
        </div>
        <div 
            className="review_title review"
        >
            <label 
                htmlFor="review_title">Title of this review
            </label>
            <input 
                placeholder='Whats the title of your review' 
                type="text" 
                name="review_title" 
                id="review_title" 
            />
        </div>
        <div 
            className="review"
        >
            <label 
                htmlFor="experience">Tell us about your experience
            </label>
            <textarea           
                name="experience" 
                id="experience" 
                cols="30" 
                rows="10">
            </textarea>
        </div>
        <button>Submit</button>
    </form>
  )
}

export default ReviewForm