import React,{useEffect} from 'react'
import './serum.css'

// imports 
import SerumLanding from './partials/SerumLanding'
import Products from './partials/Products'
import Footer from '../../Footer/Footer'

const Serum = ({serum, broll_images}) => {
    const cloudName = 'dyzydm9nl';

    useEffect(()=>{
      window.scrollTo(0,0)
    },[])

  return (
    <div className='serum'>
      <SerumLanding broll_images={broll_images} cloudName={cloudName} />  
      <Products serum={serum} cloudName={cloudName} />
      <Footer />
    </div>
  )
}

export default Serum 