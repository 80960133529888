import React, { useState } from 'react'
import emailjs from "@emailjs/browser"
import {BiEdit} from 'react-icons/bi'

const Results = ({quizResults, nextStep}) => {
  const [isReadOnly, setIsReadOnly] = useState(true)
  const {email, firstName, lastName, questionOne, questionTwo, questionThree, questionFour, questionFive} = quizResults;

  const handleClick = (e) => {
    setIsReadOnly(false)
    alert('Edit mode has been turned on. You can now edit your responses.')
  }

  const handleSubmit = (e) =>{
    e.preventDefault()
    emailjs.sendForm('service_9aqmc0q', 'template_bawflex', e.target, 'u9VJqE7AuO9xLxWsT')
    emailjs.sendForm('service_9aqmc0q', 'template_augx4kw', e.target, 'u9VJqE7AuO9xLxWsT')
    alert('Results have been recorded. You will recieve an email shortly with more information!')
    nextStep('thank you card')
  }

  return (
    <div className='verify_results'>
      <h1>Here are your results from the quiz!</h1>
      <h1>Please review your answers to make sure they are correct.</h1>
      <form onSubmit={handleSubmit}>
        <div className="verify_question">
          <div className="question">
            <h2>Contact Info</h2>
            <input readOnly={isReadOnly} required type="text" name="firstName" defaultValue={firstName} />
            <input readOnly={isReadOnly} required type="text" name="lastName" defaultValue={lastName} />
            <input readOnly={isReadOnly} required type="email" name="email" defaultValue={email} />
          </div>
          <div className="edit">
            <BiEdit className='editBtn' size={40} onClick={handleClick}/>
          </div>
        </div>
        <div className="verify_question">
          <div className="question">
            <h2>1. What’s your skin type?</h2>
            <input readOnly={isReadOnly} required type="text" name="questionOne" defaultValue={questionOne} />
          </div>
          <div className="edit">
            
          </div>
        </div>
        <div className="verify_question">
          <div className="question">
            <h2>2. Do you have a skin care routine?</h2>
            <input readOnly={isReadOnly} required type="text" name="questionTwo" defaultValue={questionTwo}/>
          </div>
          <div className="edit">
            
          </div>
        </div>
        <div className="verify_question">
          <div className="question">
            <h2>3a. What products are on/in your shelf?</h2>
            <input readOnly={isReadOnly} required type="text" name="questionThreeFirst" defaultValue={questionThree.firstValue}/>
          </div>
          <div className="edit">
            
          </div>
        </div>
        <div className="verify_question">
          <div className="question">
            <h2>3b. What are you incorporating into your AM|PM skin schedule?</h2>
            <input readOnly={isReadOnly} required type="text" name="questionThreeSecond" defaultValue={questionThree.secondValue}/>
          </div>
          <div className="edit">
            
          </div>
        </div>
        <div className="verify_question">
          <div className="question">
            <h2>4. Do you have any skin condition?</h2>
            <input readOnly={isReadOnly} required type="text" name="questionFour" defaultValue={questionFour}/>
          </div>
          <div className="edit">
            
          </div>
        </div>
        <div className="verify_question">
          <div className="question">
            <h2>5. What are your skin goals?</h2>
            <input readOnly={isReadOnly} required type="text" name="questionFive" defaultValue={questionFive}/>
          </div>
          <div className="edit">
            
          </div>
        </div>
        <button>Submit</button>
      </form>   
    </div>
  )
} 

export default Results