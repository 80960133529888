import React from 'react'
import './faq.css'
import Accordion from './Accordion'
import { useEffect } from 'react'

const Faq = () => {
    
    useEffect(()=>{
        window.scroll(0,0)
    },[])
  return (
    <>
        <div className="faq">
            <div className="faq-banner">
                <h1>FAQ</h1>
            </div>    
            <div className="faq-accordion">
                <Accordion />  
           </div>
        </div>
        {/* <Footer /> */}
    </>
  )
}

export default Faq