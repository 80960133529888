import { Image } from 'cloudinary-react'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

const Cancel = ({broll_images}) => {
  const cloudName = 'dyzydm9nl'
  const isMobile = useMediaQuery({ query: `(max-width: 767px)` })

  return (
    <div className='success'>
      <div className="left">
        <h1>Payment Error</h1>
        {
          isMobile &&
            broll_images.map(image => image.name === 'try again' && 
            <div key={image.id}>
              <Image cloudName={cloudName} publicId={image.image}/>
            </div>
          )
        }
        <p>Oops! Seems like we ran into some trouble with your payment. Please try again, if you come across any further issues contact us at <a target='_blank' href="mailto:bearysoftorganic@gmail.com">Bearysoftorganic@gmail.com</a> <br /> Thank you! </p>
        <Link to='/'><button>Return to home</button></Link>
      </div>
      <div className="right">
      {
          !isMobile &&
            broll_images.map(image => image.name === 'try again' && 
            <div key={image.id}>
              <Image cloudName={cloudName} publicId={image.image}/>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default Cancel