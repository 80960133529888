import React, {useEffect} from 'react'

const QuestionTwo = ({nextStep}) => {

    const handleClick = (e) => {
        nextStep('question three', e.target.innerText)
    }
 
    useEffect (()=>{
        window.scrollTo(0,0)
  
    },[])

  return (
    <div>
        <div className='q_a'>
            <div className="questions">
                <h1>2. Do you have a skin care routine?</h1>
            </div>
            <div className="answers">
                <div onClick={(e)=>handleClick(e)} className="container">
                    <strong>Yes</strong>
                </div>
                <div onClick={(e)=>handleClick(e)} className="container">
                    <strong>No</strong>
                </div>
            </div>
        </div>
    </div>
  )
}

export default QuestionTwo