import React from 'react'
import { Image } from 'cloudinary-react';
import { bearySoftImages } from '../../../image/image';
import { Link } from 'react-router-dom';

const RecommendedProducts = () => {
    const cloudName = 'dyzydm9nl'
    const youMightLikeProducts = bearySoftImages.body_butter.map(images => images); 

  return (
    <div className='recommended_products'>
        <h1>Other Baked by Beary - Butters</h1>
        <div className="images">
            {
                youMightLikeProducts.filter(product => product.id !== '0')
                .map(product => 
                    <span key={product.id}>
                        <Link 
                            to={`/shop/body-butter/${product.id}`}>
                            <Image className='img' cloudName={cloudName} publicId={product.image}/>
                        </Link>
                    </span>
                )
            }
        </div>
    </div>
  )
}

export default RecommendedProducts