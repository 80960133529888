// dependencies 
import React from 'react'
import { Link } from 'react-router-dom'

// imports 
import { Image } from 'cloudinary-react'

const HotProducts = ({body_butter}) => {
  const cloudName = 'dyzydm9nl'

  return (
    <div className='hot_products_container'>
         <div className="content">
            <h2>A NEW ERA OF SKINCARE</h2>
            {
              body_butter.filter(body_butter => body_butter.name === 'Baked by Beary').map(body_butter => 
                  <Link to='/shop/body-butter' key={body_butter.id} className="image">
                    <h1>{body_butter.description}</h1>
                    <Image className='img' cloudName={cloudName} publicId={body_butter.image}/>
                  </Link>
                )
            }
            <Link to='/shop/body-butter'><button>Shop Our Inventory</button></Link>
        </div>
    </div>
  )
}

export default HotProducts