import React, { useEffect } from 'react'

const ThankYouCard = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <div className='thank_you'>
        <h1>Your Results have been recorded!</h1>
        <p>Thank you for taking the time out to complete our skin session mini questionnaire. We hope your results help assist you to learning your skin  skills more in depth. The questionnaire should guide you into finding products that work according to you and develop a skincare regimen.</p>
        <p>You will recieve an email shortly with more information!</p>
    </div>
  )
}

export default ThankYouCard