import './quiz.css'
import React, {useEffect, useState} from 'react'

import QuestionOne from './partials/QuestionOne';
import FunFact from './partials/FunFact';
import QuestionTwo from './partials/QuestionTwo';
import QuestionThree from './partials/QuestionThree';
import QuestionFour from './partials/QuestionFour';
import QuestionFive from './partials/QuestionFive';
import SkinCondition from './partials/SkinCondition';
import SkinGoals from './partials/SkinGoals';
import Footer from '../Footer/Footer'
import { useSkinQuizResults } from '../../useSkinQuizResults';
import Results from './partials/Results';
import RetrieveClientInfo from './partials/RetrieveClientInfo';
import ThankYouCard from './partials/ThankYouCard';

const Quiz = () => {
    const [progressStep, setProgressStep] = useState ('');
    const [functionObject, setFunctionObject] = useSkinQuizResults();

    const nextStep = (progressStep, e) => {
        if(progressStep === 'question one'){
            setProgressStep(progressStep)
            setFunctionObject({...e})
        }
        if(progressStep === 'fun fact'){
            setProgressStep(progressStep);
            setFunctionObject({...functionObject, questionOne : e});
        }
        if(progressStep === 'question two'){
            setProgressStep(progressStep);
        }
        if(progressStep === 'question three'){
            setProgressStep(progressStep);
            setFunctionObject({...functionObject, questionTwo : e})
        }
        if(progressStep === 'question four'){
            setProgressStep(progressStep)
            setFunctionObject({...functionObject, questionThree : e})
        }
        if(progressStep === 'skin goals'){
            setProgressStep(progressStep)
            setFunctionObject({...functionObject, questionFour : e})
        }
        if(progressStep === 'question five'){
            setProgressStep(progressStep)
        }
        if(progressStep === 'get results'){
            setProgressStep(progressStep)
            setFunctionObject({...functionObject, questionFive : e})
        }
        if(progressStep === 'thank you card'){
            setProgressStep(progressStep)
        }
    }

    useEffect (()=>{
        window.scrollTo(0,0)
        setProgressStep('retrieve client info')
    },[])

  return (
    <div className='quiz'>
        <div className="quiz_container">
            {
                progressStep === 'retrieve client info' &&
                <RetrieveClientInfo 
                    nextStep={nextStep}
                />
            }
            {
                progressStep === 'question one' &&
                <QuestionOne 
                    nextStep={nextStep}
                />
            }
            {
                progressStep === 'fun fact' &&
                <FunFact 
                    nextStep={nextStep}
                />
            }
            {
                progressStep === 'question two' &&
                <QuestionTwo 
                    nextStep={nextStep}
                />
            }
            {
                progressStep === 'question three' &&
                <QuestionThree
                    nextStep={nextStep}
                />
            }
            {
                progressStep === 'question four' &&
                <QuestionFour
                    nextStep={nextStep}
                />
            }
            {
                progressStep === 'skin condition' &&
                <SkinCondition 
                    nextStep={nextStep}
                />
            }
            {
                progressStep === 'skin goals' &&
                <SkinGoals 
                    nextStep={nextStep}
                />
            }
            {
                progressStep === 'question five' &&
                <QuestionFive
                    nextStep={nextStep}
                />
            }
            {
                progressStep === 'get results' &&
                <Results  
                    quizResults={functionObject}
                    nextStep={nextStep}
                />
            }
            {
                progressStep === 'thank you card' &&
                <ThankYouCard />
            }
        </div>
        <Footer />
    </div>
  )
}

export default Quiz