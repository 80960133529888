// dependencies 
import './App.css';
import React, {useState} from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRef } from 'react'

// components
import Navigation from './Components/Navigation/Navigation';
import Home from './Components/Home/Home';
import Modal from './Components/Modal/Modal';
import Serum from './Components/Shop/Serum/Serum'
import BodyButter from './Components/Shop/BodyButter/BodyButter'
import Cart from './Components/Cart/Cart';
import Quiz from './Components/SkinQuiz/Quiz';
import Checkout from './Components/Checkout/Checkout';
import Success from './Components/Checkout/partials/Success';
import Cancels from './Components/Checkout/partials/Cancel';
import { useLocalStorage } from './useLocalStorage';

// images 
import { bearySoftImages } from './image/image';
import About from './Components/About/About';
import Faq from './Components/FAQ/Faq';
import Blog from './Components/Blog/Blog';

function App() {
  const serum = bearySoftImages.serum;
  const body_butter = bearySoftImages.body_butter;
  const broll_images = bearySoftImages.broll_images;
  const videos = bearySoftImages.videos
  const myRef = useRef()
  
  // useState 
  const [observerState, setObserverState] = useState('')
  const [clientCart, setClientCart] = useLocalStorage('cart', [])
  const [productAdded, setProductAdded] = useState(false)
  
  const addToCart = (product) => {
    const exist = clientCart.find(item => item.id === product.id)
    if(exist) {
      setClientCart(
        clientCart.map(x => 
          x.id === product.id 
            ? {...exist, qty: exist.qty + 1}
            : x
        )
      );
    }else{
      setClientCart([...clientCart, {...product, qty : 1}]); 
    }
    toggleCartAdded()
  };

  const toggleCartAdded = () => {
    setProductAdded(true)
    setTimeout(()=>{
      setProductAdded(false)
    },1500)
  };

  const removeFromCart = (product) => {
    const exist = clientCart.find(item => item.id === product.id)
    if(exist){
      setClientCart(
        clientCart.map(x => x.id === product.id
          ? {...exist, qty: exist.qty - 1}  
          : x
        )
      )
    }
    if(exist.qty <= 1) {
      setClientCart(clientCart.splice(exist, 0))
    }
  }

  return (
    <div className="App">
      <Navigation 
        broll_images={broll_images} 
        observerState={observerState}
      />
      <Routes>
        <Route 
          path='/' 
          element={<Home setObserverState={setObserverState} myref={myRef} broll_images={broll_images} serum={serum} body_butter={body_butter}/>}>
        </Route>
        <Route 
          path='/shop/serum' 
          element={<Serum broll_images={broll_images}serum={serum} />}>  
        </Route>
        <Route 
          path='/shop/serum/:productId' 
          element={<Modal clientCart={clientCart} productAdded={productAdded} addToCart={addToCart} serum={serum}/>}>
        </Route>
        <Route 
          path='/shop/body-butter' 
          element={<BodyButter body_butter={body_butter} />}>
        </Route>
        <Route 
          path='/shop/body-butter/:productId' 
          element={<Modal serum={serum} addToCart={addToCart} body_butter={body_butter} />}>
        </Route>
        <Route 
          path='/cart'
          element={<Cart clientCart={clientCart} addToCart={addToCart} removeFromCart={removeFromCart}/>}>
        </Route>
        <Route 
          path='/skin-quiz'
          element={<Quiz />}>
        </Route>
        <Route 
          path='/about'
          element={<About broll_images={broll_images} />}>
        </Route>
        <Route 
          path='/checkout'
          element={<Checkout clientCart={clientCart} videos={videos}/>}>
        </Route>
        <Route 
          path='/success'
          element={<Success setClientCart={setClientCart} broll_images={broll_images} />}>
        </Route>
        <Route 
          path='/cancel'
          element={<Cancels broll_images={broll_images} />}>
        </Route>
        <Route 
          path='/FAQ'
          element={<Faq />}>
        </Route>
        <Route 
          path='/blog'
          element={<Blog />}>
        </Route>
      </Routes>    
    </div>
  );
}

export default App;

