// dependencies
import React from 'react'
import { Image } from 'cloudinary-react'
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
 
const LandingPage = ({serum}) => {
  const cloudName = 'dyzydm9nl'
  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  return (
    <div className='landingPage_container'>
      {
        serum.map(serum =>  
          <div key={serum.id} className='image'>
            <Image className='img' cloudName={cloudName} publicId={serum.image2}/>
            {isMobile ? <Link to='/shop/serum'><button>View Serum</button></Link> : undefined}
          </div>
        )
      }
      <div className="content">
        <meta title="Transform Your Skin with Natural, Effective Skin Care Products | Beary Soft Organic" name="Beary soft organic - Natural and Effective Skin Care Products for Beautiful, Healthy Skin" content="Welcome to Beary Soft Organic, where we believe that healthy, radiant skin starts with the power of nature. Our natural and effective skin care products are formulated with the highest quality ingredients to nourish and protect your skin. Whether you're looking for anti-aging serums, hydrating moisturizers, or gentle cleansers, we have everything you need to achieve your best skin yet. Our products are free from harsh chemicals and synthetic additives, so you can feel good about what you're putting on your skin. Shop our collection today and discover the transformative power of nature for yourself." />
        <h1>Living the soft life includes having soft skin!</h1>
        <div className='bottom'>
          <h2 >The ultimate hydrating serum</h2>
          <p>Granted Glow is providing skin solution. Rich in vitamin-C, the elixir targets taming acne breakouts, diminishing scars and dark marks. Overall keeping the skin moisturized aiding to a smoother texture. Granted Glow can be added into your morning and nighttime skincare routine! Say hello to refreshed skin as Granted Glow provides radiant, youthful and clearer skin!</p>
          {isMobile ? undefined : <Link to='/shop/serum'><button>View Serum</button></Link>}
        </div>
      </div>
    </div>
  )
}

export default LandingPage