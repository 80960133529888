import React from 'react'
import { Image } from 'cloudinary-react'
import './cart.css'
import {BsFileMinus, BsFilePlus} from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import axios from 'axios'

const Cart = ({clientCart, removeFromCart ,addToCart}) => {
    const cloudName = 'dyzydm9nl';
    const totalPrice = clientCart.reduce((price, item) => price + item.qty * item.price, 0) 
    const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

    const renderStripePayment = async () => {
        await axios('https://bearysoftorganic.com/.netlify/functions/server', {
            method: 'post',
            headers: {
                "Content-Type": "application/json"
            },
            data: JSON.stringify({items: clientCart})
        })
        .then(resp => {
            return resp.data
        })
        .then(resp =>{
            if(resp.message){
                return window.location.assign(resp.message);
            }
        })
    }
    
    return (
    <div className='cart_page'>
        <div className="cart_container">
            {
                clientCart.length === 0 ? 
                <div className="cart_empty">
                    <h1>Your cart is empty</h1>
                    <Link 
                        to='/shop/serum'>
                        <button>Lets shop!</button>
                    </Link>
                </div>
                :
                <>
                 <h1>Cart</h1>
                 {
                    !isMobile &&
                    <div className="header">
                        <strong className='product'>Product</strong>
                        <strong className='product'>Name</strong>
                        <strong>Quantity</strong>
                        <strong>Total</strong>
                    </div>
                 }
                    <div className="container">
                        {
                            clientCart.map(cartProduct => 
                                <div key={cartProduct.id} className="cart_item_list">
                                    <div className="cart_info">
                                        <div className="image">
                                            <Image 
                                                cloudName={cloudName} 
                                                publicId=
                                                    {
                                                        cartProduct.typeOf === 'serum' ?
                                                        cartProduct.image1 : 
                                                        cartProduct.image
                                                    }
                                                />
                                        </div>
                                        <div className="description">
                                            <h2>{cartProduct.name}</h2>
                                        </div>
                                        <div className="quantity">  
                                            <div className='quantity_display'>
                                                <BsFileMinus onClick={()=>removeFromCart(cartProduct)}/>
                                                <strong>{cartProduct.qty}</strong>
                                                <BsFilePlus onClick={()=>addToCart(cartProduct)}/>
                                            </div>
                                        </div>
                                        <div className="total">
                                            <strong>${cartProduct.price * cartProduct.qty}</strong>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className='cart_final_total'>
                        <h2>Total Price: ${totalPrice}</h2>
                    </div>
                    <div className="navigate_options">
                        <div className="navigate-container">
                            <button onClick={renderStripePayment}>Continue to Checkout</button>
                        </div>
                        <Link to='/shop/serum'>
                            <div className="navigate-container">
                                <button className='return_to_shop'>Continue Shopping</button>
                            </div>
                        </Link>
                    </div>
                </>
            }
        </div>
    </div>
  )
}

export default Cart