import React from 'react'

const AboutLandingMobile = () => {
  return (
    <div className='about_landing_mobile'>
       <h1>About Beary Soft Organic</h1>
       <div className="mobile_container first">
          <p className='paragraph_one'> A reflective pause is what I needed to redeem myself and rebirth a new beginning with my brand/business. These last few months I’m sure I was deeply missed with the equal feeling reciprocation from both sides or either speculated about. It’s safe to say I am healing, healthy & wholesome! </p> 
       </div>
       <div className="mobile_container">
          <p className='extra_space'> During my hiatus I was able to sit back and focus on my goals, the essence and longevity of my legacy with BearySoftOrganic. I was stuck in my thoughts the midst of life chaos, so trust me when I say this project I’ve produced is worthwhile! I ultimately pushed my creativity out into the forefront and I am wholeheartedly proud of my innovation. It’s deeper than a brand, it’s my purpose. I pray this portion of life touches you in a way you understand my process. </p>
       </div>
       <div className="mobile_container third">
          <p className='content_three'> BSO is here to innovate the narrative and show how using natural products enhances us to accept our raw and barefaced beauty inside and out! Thank you for sticking beside us and joining me in the soft life journey! I hope you love the products from the collection!  We’re deeper than a skincare line, BearySoftOrganic is bringing back the trend of using natural products to preserve our natural beauty! As we are individuals whom comes from the sun, we must properly take care of our skin and feed it nutrients. It’s not stressed enough how societal standards of beauty has done more to breakdown our divine biological model than to bulid it up. </p>
       </div>
    </div>
  )
}

export default AboutLandingMobile