import React from 'react'
import { useMediaQuery } from 'react-responsive'
import './checkout.css'

const Checkout = ({videos, clientCart}) => {
    const isMobile = useMediaQuery({query: `(max-width: 767px)`})
   
  return (
    <div className='checkout'>
        {
            !isMobile && 
            <div className="media_container">
            {
                videos.filter(video => video.name === 'checkout video')
                .map(video => 
                    <div key={video.id} className='video_container'>
                        <video width="320" height="240" autoPlay loop muted>
                            <source src={video.video} type="video/mp4"/>
                            <source src={video.video} type="video/ogg"/>
                            Your browser does not support  the video tag.
                        </video>
                    </div>
                )
            }
        </div>
        }
    </div>
  )
}

export default Checkout