export const bearySoftImages = {
    serum:  [
            {
                description: 'Formulated to secure skin solutions to all skin types. “Granted Glow” is infused with jojoba oil, pomegranate seed oil + cranberry seed oil. Rich in vitamin C, the serum supports our skin natural abilities by boosting collagen production. The elixir keeps the skin smooth, hydrated & nourished. “Granted Glow” restores the skin vitality promoting radiant and rejuvenated texture.',
                id: "price_1MhLjqCUUp6fiPdVabZZzONY",
                name: 'Granted Glow',
                price: 28,
                image11: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1672668775/hl-7443033387_nnd98w.jpg',
                image10: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1672970192/hl-7443033392_wrb89z.jpg',
                image9: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1672668765/hl-7443033390_yhzqg4.jpg',
                image8: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1672668760/hl-7443033391_aqg6ut.jpg',
                image7: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1672668755/hl-7443033389_euscah.jpg',
                image6: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1672668749/hl-7443033388_bbumjq.jpg',
                image5: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1672668743/hl-7443033393_lux4so.jpg',
                image4: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1672668720/hl-7443033386_oycsty.jpg',
                image3: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1666448881/landingImage3_ouyuv1.png',
                image2: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1666448871/landingImage2_rflq6t.png',
                image1: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1666448836/landingImage1_jdn4rp.png',
                reviews: [
                    {
                        id: '1',
                        reviewerName: '',
                        review: 'NO REVIEWS YET',
                    },
                    {
                        id: '2',
                        reviewerName: 'Janiha Battle',
                        review: ' GRANTED GLOW has saved my life! You have saved my life with this amazing product. Thank you so much for the serum it has helped my face 10x better!  It has allowed my face glow & shine; it also stopped my face from being dry. I am pleased by the result of it not being dry in the last 2 1/2 week. Honestly, I am going to buy 2 more from you. Thank you so much, I am grateful to have this product.',
                        instagram: '@Janihab_photography '
                    },
                    {
                        id: '3',
                        reviewerName: 'Haddi',
                        review: 'Granted Glow has granted me the opportunity to have better skin. It’s a awesome product and I’m extremely satisfied with this product. Granted Glow serum has help me in many ways I feel more confident having better skin. It stopped my face from being dry, thanks a lot ! I am thankful to have this product.',
                        instagram: '@s0nofaqueen'
                    },
                ],
                howToUse: 'https://res.cloudinary.com/dyzydm9nl/video/upload/v1671951038/b421afbc13d5476a85df26a892647872_omjqza.mov',
                typeOf: 'serum',
                qty: 0
            },
        ],
    body_butter: [
            {
                id: '0',
                name: 'Baked by Beary',
                image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1666393184/bodyButterCollection-removebg-preview_vzizg0.png',
                description: 'Baked by Beary - Sweet Scents Collection',
                typeOf: 'bodyButter',
            },
            {
                id: '1',
                name: 'Brown Gold',
                price: 35,
                image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1666393200/brownGold-removebg-preview_f5velf.png',
                description: 'A batch of Brown Gold coming right on up. Fresh out the oven, don’t forget to grab yours!',
                typeOf: 'bodyButter',
                reviews: {
                    Brownies : {
                        id: 'Brownies',
                        reviewerName: 'Kat Williams',
                        review: 'lorem iplsm'
                    }
                },
                howToUse: 'lorem Ipsom',
                qty: 0
            },
            {
                id: '2',
                name: 'Berry Litty In The City',
                price: 35,
                image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1666393164/berryLitty_qs6l81.png',
                description: 'Berry litty in the city, always represent the brand!',
                typeOf: 'bodyButter',
                reviews: [
                    {
                        id: 'Strawberry Shortcake',
                        reviewerName: 'Kat Williams',
                        review: 'lorem iplsm 1'
                    },
                ],
                howToUse: 'lorem Ipsom',
                qty: 0
            },
            {
                id: '3',
                name: 'Cacao Dreams',
                price: 35,
                image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1666393212/cacaoDreams_wqppx4.png',
                description: 'Cacao Dreams are the sweetest sound. Our skin is working overnight while we’re resting.',
                typeOf: 'bodyButter',
                reviews: [
                    {
                        id: 'Coffee Cake',
                        reviewerName: 'Kat Williams',
                        review: 'lorem iplsm 2'
                    },
                ],
                howToUse: 'lorem Ipsom',
                qty: 0
            },
            {
                id: '4',
                name: 'Do It Like Your B-day',
                price: 35,
                image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1666393224/doItLikeYourBday_ailanr.png',
                description: 'Why not live your best life? Treat yourself, Do it like your Bday! ',
                typeOf: 'bodyButter',
                reviews: [
                    {
                        id: 'Birthday Cake',
                        reviewerName: 'Kat Williams',
                        review: 'lorem iplsm 3'
                    },
                ],
                howToUse: 'lorem Ipsom',
                qty: 0
            },
            {
                id: '5',
                name: 'Frostie Flaked',
                price: 35,
                image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1666393238/frostieFlaked_du222u.png',
                description: 'No Frostie Flaked skin this winter season. Keep me close during the cold.',
                typeOf: 'bodyButter',
                reviews: [
                    {
                        id: 'Winter Mint',
                        reviewerName: 'Kat Williams',
                        review: 'lorem iplsm 4'
                    },
                ],
                howToUse: 'lorem Ipsom',
                qty: 0
            },
            {
                id: '6',
                name: 'I Know The Muffin Man',
                price: 35,
                image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1666393253/muffinMan_o4d9vl.png',
                description: 'I know the Muffin Man has the skin solutions.',
                typeOf: 'bodyButter',
                reviews: [
                    {
                        id: 'Blueberry Muffin',
                        reviewerName: 'Kat Williams',
                        review: 'lorem iplsm 5'
                    },
                ],
                howToUse: 'lorem Ipsom',
                qty: 0
            },
            {
                id: '7',
                name: 'Sweet Spots',
                price: 35,
                image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1666393261/sweetSpot_vjjc7w.png',
                description: 'Save a Sweet Spot for yourself, put a cookie in the jar.',
                typeOf: 'bodyButter',
                reviews: [
                    {
                        id: 'Chocolate Chip Cookies',
                        reviewerName: 'Kat Williams',
                        review: 'lorem iplsm 6'
                    },
                ],
                howToUse: 'lorem Ipsom',
                qty: 0
            },
            {
                id: '8',
                name: 'Sugar Coated',
                price: 35,
                image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1666393481/sugarCoated-removebg-preview_bonghi.png',
                description: 'Sugar Coated with love. Swirl and twirl this sweetie into your heart.',
                typeOf: 'bodyButter',
                reviews: [
                    {
                        id: 'Cotton Candy',
                        reviewerName: 'Kat Williams',
                        review: 'lorem iplsm 7'
                    },
                ],
                howToUse: 'lorem Ipsom',
                qty: 0
            },
    ],
    broll_images: [
        {
            id: '11',
            name: 'Skin Playlist',
            image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1671076427/playlist_kaxmz1.png',
        },
        {
            id: '12',
            name: 'Acne Text',
            image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1666449040/acneText_boprxm.png',
        },
        {
            id: '13',
            name: 'logo',
            image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1666577711/nobglogo_zouevv.png',
        },
        {
            id: 'acdc1',
            name: 'landing image 1',
            image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1671985828/3_a2rzkl.jpg',
        },
        {
            id: 'acdc2',
            name: 'landing image 2',
            image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1671985823/2_cupou1.jpg',
        },
        {
            id: 'acdc3',
            name: 'landing image 3',
            image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1671985816/1_peuvxo.jpg',
        },
        {
            id: 'acdc4',
            name: 'whiteheads',
            image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1672669778/unnamed_ceiw2v.jpg',
        },
        {
            id: 'acdc5',
            name: 'blackheads',
            image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1672670152/unnamed_r4slaa.jpg',
        },
        {
            id: 'success',
            name: 'successful payment',
            image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1672990309/confirmation-approval-order-operation-payment-successful-completion-girl-confirms-business-success-man-hand-shows-class-239104068-removebg-preview_sspw6b.png',
        },
        {
            id: 'failed',
            name: 'try again',
            image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1673209749/payment-error-info-message-smartphone-customer-cross-marks-failure_106788-2322-removebg-preview_rklq9t.png',
        },
        {
            id: 'about page first image',
            name: 'about page faces',
            image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1675129019/IMG_1393_ifdnwc.jpg',
        },
        {
            id: 'blank image',
            name: 'about page blank',
            image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1675126643/photo-1612538498613-35c5c8d675c4_koeioq.jpg',
        },
        {
            id: 'bso ceo',
            name: 'bso ceo face',
            image: 'https://res.cloudinary.com/dyzydm9nl/image/upload/v1677786516/IMG_1327_kkowo8.jpg',
        },
    ],
    videos : [
        {
            id : "16",
            name: "landing image",
            video : "https://res.cloudinary.com/dyzydm9nl/video/upload/v1671484646/50250c2e178e498d8ad039b92b9baa84_pgiqgo.mov"
        },
        {
            id : "17",
            name: "checkout video",
            video : "https://res.cloudinary.com/dyzydm9nl/video/upload/v1671909331/checkout_txgjoe.mov"
        },
    ]
}