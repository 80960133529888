import React, {useState} from 'react'
import ReviewForm from './ReviewForm'
import { useParams } from 'react-router-dom';

const Reviews = ({serumReviews, bodyButterReviews}) => {
  const [renderReviews, setRenderReviews] = useState(false);
  const [renderReviewForm, setRenderReviewForm] = useState(false);

  const {productName} = useParams()

  return (
    <div className='reviews_container'>
      <h1 onClick={()=>setRenderReviews(!renderReviews)}>Reviews</h1>
      {
        renderReviews &&
          <div className="accordian_drop">
            {
              serumReviews ?
              <>
                {
                  serumReviews.map(review => 
                    <div className="reviews" key={review.id}>
                      <h2>{review.reviewerName}</h2>
                      <p>{review.review}</p>
                    </div>
                  )
                }
              </> 
                :
                  bodyButterReviews.filter(review => review + "." + productName === productName).map(review =>
                      <div className="reviews" key={review + "." + productName.id}>
                        <h2>{review + "." + productName.reviewerName}</h2>
                        <p>{review + "." + productName.review}</p>
                      </div>
                  )
            }
            <strong 
              className='write-review'
              onClick={()=>setRenderReviewForm(!renderReviewForm)}>Write A Review?
            </strong>
            {
              renderReviewForm && 
                <ReviewForm />
            }
          </div>
      }
    </div>
  )
}

export default Reviews