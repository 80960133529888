import { Image } from 'cloudinary-react'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
 
const SerumLanding = ({broll_images, cloudName}) => {
    const isTablet = useMediaQuery({query: `(min-width: 768px) and (max-width: 1023px)`})
    
  return (
    <div className='serum_landing_container'>
        <meta title="Beary Soft Organic - Transform Your Skin with Our Award-Winning Granted Glow" name="Granted Glow - Award-Winning natural skin care product for Transformative Skin Care" content="Introducing Granted Glow, our award-winning natural skin care product that will transform your skin care routine. Our advanced formula is specially designed to nourish and revitalize your skin, leaving it looking healthy and radiant. With powerful natural ingredients like jojoba oil, pomegranate seed oil + cranberry seed oil. Rich in vitamin C, this product is gentle yet effective, working to reduce the appearance of fine lines and wrinkles, improve hydration, and promote a more even skin tone. Whether you're looking to target specific skin concerns or simply want to enhance your overall skin health, Granted Glow is the perfect choice. Try it today and see the difference it can make for your skin."/>
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div className='carousel_banner'>
                        <p>Think of acne like a volcano erupting , the burp if the earth is a representation of your skin when it’s hold bacteria & other dis-eased fluids. Acne is known as a chronic disease with no long term cure because it can occur overtime throughout your lifetime. The formulation of acne happens when hair follicles in our skin , clogs together with oil and dead skin cells. The severity of the bacteria triggers the inflammation and pus creating a whitehead or blackhead bump.</p>
                        {
                            broll_images.filter(broll_image => broll_image.name === 'Acne Text').map(broll_image => 
                                <div 
                                    key={broll_image.id} 
                                    className='carousel_img'>
                                    <Image 
                                        cloudName={cloudName} 
                                        publicId={broll_image.image}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="carousel-item">
                    <div className='carousel_banner'>
                        <div className="banner_right">
                            <div className="carousel_img">
                                {
                                    broll_images.map(image => image.name === 'whiteheads' && 
                                        <Image key={image.id} cloudName={cloudName} publicId={image.image} />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className='carousel_banner'>
                        <div className="banner_right">
                            <div className="carousel_img">
                                {
                                    broll_images.map(image => image.name === 'blackheads' && 
                                        <Image key={image.id} cloudName={cloudName} publicId={image.image}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button 
                className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>
  )
}

export default SerumLanding