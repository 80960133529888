import React from 'react'
import { Link} from 'react-router-dom'


// images 
import { Image } from 'cloudinary-react'

const Products = ({serum, cloudName}) => {

  return (
    <div className='serum_products_container'>
        {
            serum.map(serumImage => 
                <div key={serumImage.id} className='images'>
                    <h2>Meet our #1 Product</h2>
                    <h1>{serumImage.name}</h1>
                    <Link 
                        to={`/shop/serum/${serumImage.id}`}> 
                    <Image 
                        className='img' 
                        cloudName={cloudName} 
                        publicId={serumImage.image8}
                    />
                    </Link>
                    <Link to={`/shop/serum/${serumImage.id}`}><button>View Granted Glow</button></Link>
                </div>    
            )
        }
        <div className="info">
            <p>"Formulated to secure skin solutions to all skin types. “Granted Glow” is infused with jojoba oil, pomegranate seed oil + cranberry seed oil. Rich in vitamin C, the serum supports our skin natural abilities by boosting collagen production... " </p>
        </div>
    </div>
  )
}

export default Products