import React from 'react'
import Reviews from './Reviews'
import HowToUse from './HowToUse'

const Accordion = ({serum, arrayIndex, serumReviews, bodyButterReviews}) => {
  return (
    <div className='accordian'>
        <div className="accordians">
            <div className="container">
                <HowToUse serum={serum} />
            </div>
            <div className="container">
                <Reviews 
                    serumReviews={serumReviews} 
                    bodyButterReviews={bodyButterReviews}
                    arrayIndex={arrayIndex}
                /> 
            </div>
        </div>
    </div>
  )
}

export default Accordion