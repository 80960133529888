import React from 'react'
import './footer.css'
import emailjs from '@emailjs/browser'
import { Link } from 'react-router-dom'

const Footer = () => {

    const onSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_9aqmc0q', 'template_s4jua0k', e.target, 'u9VJqE7AuO9xLxWsT')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()

          alert('Access Granted! Thank you for subscribing')
    }

  return (
    <div className='footer'>
        <div className="footer_container">
            <div className="column">
                <h2>OUR PRODUCTS</h2>
                <ul className="list_items">
                    <li><Link to='/shop/serum'>Shop Serums</Link></li>
                    <li><Link to='/shop/body-butter'>Shop Body Butters</Link></li>
                </ul>
            </div>
            <div className="column">
                <h2>ABOUT US</h2>
                <ul className="list_items">
                    <li><Link to='/about'>About Beary Soft Organic</Link></li>
                    <li><Link to='/FAQ'>FAQ</Link></li>
                </ul>
            </div>
            <div className="column">
                <h2>SOCIAL</h2>
                <ul className="list_items">
                    <a href="https://www.instagram.com/bearysoftorganic/?hl=en" target="_blank"><li>Instagram</li></a>
                </ul>
            </div>
            <div className="column">
                <h2>NEWSLETTER</h2>
                <div className="list_items">
                    <h3>Make sure to stay updated with what’s new with BSO! Drop your email in the box below to subscribe to our newsletter. </h3>
                    <form onSubmit={onSubmit}>
                        <div className="name_inputs">
                            <input required type="text" name="first_name" id="first_name" placeholder='First Name'
                             />
                            <input required type="text" name="last_name" id="last_name" placeholder='Last Name' />
                        </div>
                        <div className="email_input">
                            <input required type="email" name="email" id="email" placeholder='Email' />
                        </div>
                        <button className='sign_up'>Sign Up</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer