import React, { useEffect } from 'react'

const SkinCondition = ({nextStep}) => {

    useEffect(()=>{
      window.scrollTo(0,0)
      
      setTimeout(() => {
        nextStep('')
      }, 3500);
      },[])

  return (
    <div className='fun_fact'>
        <div className="fun_fact_container">
            <p> Skin spill please, I need to know if you have any allergies. It also helps for recommendations of products that heals your skin suffers rather that be eczema, psoriasis, rosacea & etc.</p>
        </div>
    </div>
  )
}

export default SkinCondition