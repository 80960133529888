import React from 'react'
import emailjs from '@emailjs/browser'
import './blog.css'

const Blog = () => {

  const onSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9aqmc0q', 'template_s4jua0k', e.target, 'u9VJqE7AuO9xLxWsT')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
      
      alert('Access Granted! Thank you for subscribing')
  }

  return (
    <div className='blog'>
      <h1>Coming Soon!</h1>
      <h2> Please leave your information below to subscribe to our newsletter to stay updated for when our blog drops!</h2>
      <form onSubmit={onSubmit} className='blog_form'>
        <div className="name">
          <input required type="text" name="first_name" id="first_name" placeholder='First Name' />
          <input required type="text" name="last_name" id="last_name" placeholder='Last Name' />  
        </div>
        <input required type="email" name="email" id="email" placeholder='Email' />
          <button>Sign Up</button>
      </form>
    </div>
  )
}
export default Blog