import React, {useEffect, useRef} from 'react'

const QuestionOne = ({nextStep}) => {

    const typeOne = useRef()
    const typeTwo = useRef()
    const typeThree = useRef()
    const typeFour = useRef()
    
    const handleUserInput = (e) => {
        nextStep('fun fact', e.target.innerText)
     }

    useEffect (()=>{
        window.scrollTo(0,0)
    },[])

  return (
    <>
        <div className="q_a">
            <div className="questions">
                <h1> 1. What’s your skin type? </h1>
            </div>
            <div className='answers'>   
                <div onClick={(e)=>handleUserInput(e)} className="container">
                    <label htmlFor="noraml">Normal</label>
                </div>
                <div onClick={(e)=>handleUserInput(e)} className="container">
                   <label htmlFor="Dry">Dry</label>
                </div>
                <div onClick={(e)=>handleUserInput(e)} className="container">
                   <label htmlFor="oily">Oily</label>
                </div>
                <div onClick={(e)=>handleUserInput(e)} className="container">
                   <label htmlFor="combination">Combination</label>
                </div>
            </div>
        </div>
        <div className="info_bubble">
            <h2>Tip : <span>There are 4 different skin categories, which one does your skin drop into?</span></h2>
        </div> 
    </>
  )
}

export default QuestionOne